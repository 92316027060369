/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./purchase-single.vue?vue&type=template&id=337d0e31&"
import script from "./purchase-single.vue?vue&type=script&lang=ts&"
export * from "./purchase-single.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VContainer,VExpandTransition})

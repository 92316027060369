import Vue from 'vue';
import { TicketStatus } from '@common/models.g';
export default Vue.extend({
  props: {
    ticket: {
      required: true
    }
  },
  data: function data() {
    return {
      TicketStatus: TicketStatus
    };
  }
});
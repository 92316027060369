// Polyfills for IE support
import 'babel-polyfill';

import Vue from 'vue';
import App from './App.vue';
import router from './router';

import TicketingCommon, { VuetifyOptions } from '@common/setup';
Vue.use(TicketingCommon, {
  publicUser: true,
});

declare module '@public/models.g' {
  export interface TicketPurchaseDto {
    localRawCreditCardDetails?: {
      [paymentId: number]: CreditCardDetails;
    };
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    readonly $isPublicUser: boolean;
  }
}

Object.defineProperty(Vue.prototype, '$isPublicUser', {
  get() {
    return true;
  },
});

// SETUP: vuetify
import Vuetify from 'vuetify/lib';
const vuetify = new Vuetify(VuetifyOptions);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

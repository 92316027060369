import * as $metadata from './metadata.g'
import * as $models from './models.g'
import { AxiosClient, ModelApiClient, ServiceApiClient, ItemResult, ListResult } from 'coalesce-vue/lib/api-client'
import { AxiosPromise, AxiosResponse, AxiosRequestConfig } from 'axios'

export class EventDateDtoApiClient extends ModelApiClient<$models.EventDateDto> {
  constructor() { super($metadata.EventDateDto) }
}


export class EventDtoApiClient extends ModelApiClient<$models.EventDto> {
  constructor() { super($metadata.EventDto) }
}


export class EventTypeDtoApiClient extends ModelApiClient<$models.EventTypeDto> {
  constructor() { super($metadata.EventTypeDto) }
}


export class TicketPurchaseDtoApiClient extends ModelApiClient<$models.TicketPurchaseDto> {
  constructor() { super($metadata.TicketPurchaseDto) }
}


export class PublicPurchaseServiceApiClient extends ServiceApiClient<typeof $metadata.PublicPurchaseService> {
  constructor() { super($metadata.PublicPurchaseService) }
  public initiatePurchase(eventDateId: number | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.initiatePurchase
    const $params =  {
      eventDateId,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public getSeating(publicId: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.EventDateSeatingInfo>> {
    const $method = this.$metadata.methods.getSeating
    const $params =  {
      publicId,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public setTicketQuantity(publicId: string | null, eventPriceId: number | null, quantity: number | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.setTicketQuantity
    const $params =  {
      publicId,
      eventPriceId,
      quantity,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public addTicket(publicId: string | null, eventPriceId: number | null, seat: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.addTicket
    const $params =  {
      publicId,
      eventPriceId,
      seat,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public removeTicket(publicId: string | null, ticketId: number | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.removeTicket
    const $params =  {
      publicId,
      ticketId,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public setAddonQuantity(publicId: string | null, addonConfigId: number | null, quantity: number | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.setAddonQuantity
    const $params =  {
      publicId,
      addonConfigId,
      quantity,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public setDietaryRestriction(publicId: string | null, allergen: string | null, quantity: number | null, comment: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.setDietaryRestriction
    const $params =  {
      publicId,
      allergen,
      quantity,
      comment,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public removeDiscount(publicId: string | null, discountId: number | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.removeDiscount
    const $params =  {
      publicId,
      discountId,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public applyDiscountCode(publicId: string | null, discountCode: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.TicketPurchaseDto>> {
    const $method = this.$metadata.methods.applyDiscountCode
    const $params =  {
      publicId,
      discountCode,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public completePublicCardPayment(publicId: string | null, cardDetails: $models.CreditCardDetails | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<string>> {
    const $method = this.$metadata.methods.completePublicCardPayment
    const $params =  {
      publicId,
      cardDetails,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public getPostalCodeInfo(postalCode: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<$models.PostalCodeInfo>> {
    const $method = this.$metadata.methods.getPostalCodeInfo
    const $params =  {
      postalCode,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public markTicketPurchaseHoneyPotSpam(publicId: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.markTicketPurchaseHoneyPotSpam
    const $params =  {
      publicId,
    }
    return this.$invoke($method, $params, $config)
  }
  
}


export class SignInServiceApiClient extends ServiceApiClient<typeof $metadata.SignInService> {
  constructor() { super($metadata.SignInService) }
  public signIn(userName: string | null, password: string | null, rememberMe: boolean | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.signIn
    const $params =  {
      userName,
      password,
      rememberMe,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public requestPasswordReset(userName: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.requestPasswordReset
    const $params =  {
      userName,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public resetPassword(userName: string | null, code: string | null, newPassword: string | null, confirmNewPassword: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.resetPassword
    const $params =  {
      userName,
      code,
      newPassword,
      confirmNewPassword,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public setupAccount(userName: string | null, code: string | null, newPassword: string | null, confirmNewPassword: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.setupAccount
    const $params =  {
      userName,
      code,
      newPassword,
      confirmNewPassword,
    }
    return this.$invoke($method, $params, $config)
  }
  
  public confirmEmail(userId: string | null, code: string | null, newEmail: string | null, $config?: AxiosRequestConfig): AxiosPromise<ItemResult<void>> {
    const $method = this.$metadata.methods.confirmEmail
    const $params =  {
      userId,
      code,
      newEmail,
    }
    return this.$invoke($method, $params, $config)
  }
  
}



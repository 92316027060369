import type { Route, Location, RouteConfig } from 'vue-router';

/** Route name constants where the route exists in both public and private apps. */
export namespace COMMON_ROUTE_NAMES {
  export const EventDateSingle = 'event-date-single';
  export const TicketPurchaseRedirecting = 'ticket-purchase-redirecting';
  export const TicketPurchaseSingle = 'ticket-purchase-single';
}

export function matchCommonLegacyRoutes(
  to: Route,
  from: Route
): Location | void {
  // Match legacy routes from the old application and redirect to new pages.
  // We have to do manual matching on these because they contain a hash that vue-router won't match on.
  const confirmationRoute = /\/Tickets\/#\/confirmation\/(\d+)/.exec(
    to.fullPath
  );
  if (confirmationRoute) {
    return {
      name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
      params: { publicId: confirmationRoute[1] },
    };
  }

  const eventDateRoute = /\/Tickets\/#\/purchase\/(\d+)/.exec(to.fullPath);
  if (eventDateRoute) {
    return {
      name: COMMON_ROUTE_NAMES.EventDateSingle,
      params: { id: eventDateRoute[1] },
    };
  }
}

/** Routes which have identical configuration between public and private apps
 * (i.e. they use a shared view component) */
export const CommonRoutes = <RouteConfig[]>[
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@common/views/reset-password.vue'),
  },
  {
    path: '/account-setup',
    name: 'account-setup',
    component: () => import('@common/views/account-setup.vue'),
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@common/views/confirm-email.vue'),
  },
];

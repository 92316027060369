import Vue from 'vue';
import { AddonStatus } from '@common/models.g';
export default Vue.extend({
  props: {
    addon: {
      required: true
    }
  },
  data: function data() {
    return {
      AddonStatus: AddonStatus
    };
  }
});
/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.premiumRefundsOffered)?_c('labeled-section',{attrs:{"title":"Purchase Protection","icon":"fal fa-shield-check"}},[_c('v-card',{staticClass:"purchase-addon-selector",attrs:{"outlined":""}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Item")]),_vm._v(" "),_c('th',{staticClass:"purchase-addon-description-header"}),_vm._v(" "),_c('th',{staticClass:"text-right",attrs:{"width":"100px"}},[_vm._v("Subtotal")])])]),_vm._v(" "),_c('tbody',[(_vm.premiumRefundsOffered)?_c('tr',{staticClass:"purchase-addon-selector-row"},[_c('td',{staticClass:"purchase-addon-description"},[_c('span',{staticClass:"title primary-text"},[_vm._v("Purchase Protection")]),_vm._v(" "),_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.priceDescription)}})]),_vm._v(" "),_c('td',{staticClass:"text-left pr-0"},[_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"value":_vm.hasPickedRefundable ? _vm.purchase.refundable : null,"disabled":_vm.purchase.$savingProps.has('refundable'),"label":"Would you like to add purchase protection, as described below, to your order?","rules":[
                  function (v) { return _vm.hasPickedRefundable || 'Please select an option'; } ]},on:{"change":function($event){_vm.setRefundable($event);
                  _vm.hasPickedRefundable = true;}}},[_c('v-radio',{attrs:{"value":_vm.RefundableState.Purchased,"label":"Yes - Please add purchase protection."}}),_vm._v(" "),_c('v-radio',{attrs:{"value":_vm.RefundableState.Declined,"label":"No - My purchase will be final and nonrefundable."}})],1)],1),_vm._v(" "),_c('td',{staticClass:"text-right"},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.purchase.$savingProps.has('refundable'))?_c('v-skeleton-loader',{key:"skeleton",staticClass:"pt-1",attrs:{"type":"text"}}):_c('div',{key:"value"},[_vm._v("\n                  "+_vm._s(_vm.formatCurrency(
                      _vm.purchase.refundable == _vm.RefundableState.Purchased
                        ? _vm.purchase.refundableFee
                        : 0
                    ))+"\n                ")])],1)],1)]):_vm._e(),_vm._v(" "),_c('tr',{staticClass:"purchase-addon-row2"},[_c('td',{staticClass:"purchase-addon-description pb-0 text-center",attrs:{"colspan":"3"}},[_c('span',{staticClass:"title primary-text"},[_vm._v("Purchase Protection")]),_vm._v(" "),_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.priceDescription)}})])])])]},proxy:true}],null,false,4114827090)}),_vm._v(" "),_c('div',{staticClass:"px-4 py-2",staticStyle:{"white-space":"pre-wrap"},domProps:{"textContent":_vm._s(
        _vm.purchase.refundPolicy || 'PURCHASE_PROTECTION_DESCRIPTION_MISSING'
      )}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import { SignInServiceViewModel } from '@public/viewmodels.g';
export default Vue.extend({
  data: function data() {
    return {
      step: 1,
      signInService: new SignInServiceViewModel(),
      pageTitle: 'Sign In'
    };
  },
  props: {
    returnUrl: String
  },
  created: function created() {
    var _this = this;
    this.signInService.signIn.onFulfilled(function () {
      // These navigations must be performed by setting window.location
      // so that we get a full-page reload, which will then allow the server
      // to start serving up the private app (since we're currently on the public app).
      if (_this.returnUrl) {
        window.location.href = _this.returnUrl;
      } else {
        window.location.href = '/';
      }
    });
  }
});
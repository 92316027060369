import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

import Home from '@public/views/home.vue';
import SignIn from '@public/views/sign-in.vue';
import Purchase from '@public/views/purchase-single.vue';
import PurchaseRedirecting from '@common/views/purchase-redirecting.vue';
import EventDateSingle from '@common/views/event-date-single.vue';

import Error404 from '@common/views/error-404.vue';

import {
  CommonRoutes,
  COMMON_ROUTE_NAMES,
  matchCommonLegacyRoutes,
} from '@common/routing';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...CommonRoutes,
    {
      path: '/sign-in',
      name: 'sign-in',
      component: SignIn,
      props: (route) => ({ returnUrl: route.query.returnUrl }),
    },

    {
      name: 'admin-404',
      path: '/admin*',
      component: Error404,
      props: { suggestLogin: true },
    },
    {
      name: 'agent-404',
      path: '/agent*',
      component: Error404,
      props: { suggestLogin: true },
    },
    {
      name: 'error-404',
      path: '*',
      component: Error404,
    },

    ...(APP_TID >= 1
      ? <RouteConfig[]>[
          {
            path: '/',
            name: 'home',
            component: Home,
          },
          {
            path: '/event-date/:id',
            name: COMMON_ROUTE_NAMES.EventDateSingle,
            component: EventDateSingle,
            props: true,
          },
          {
            path: '/purchase/:publicId',
            name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
            component: Purchase,
            props: true,
          },
          {
            path: '/purchase/:publicId/redirecting',
            name: COMMON_ROUTE_NAMES.TicketPurchaseRedirecting,
            component: PurchaseRedirecting,
            props: (route) => ({
              publicId: route.params.publicId,
              redirectUrl: route.query.redirectUrl,
            }),
          },
        ]
      : []),
  ],
});

router.beforeEach((to, from, next) => {
  var matchedLegacyRoute = matchCommonLegacyRoutes(to, from);
  if (matchedLegacyRoute) {
    next(matchedLegacyRoute);
    return;
  }

  const calendarRoute = /\/Tickets\/#\/?calendar/.exec(to.fullPath);
  if (calendarRoute) {
    next({ path: '/', query: { tab: 'calendar' } });
    return;
  }

  const homeRoute = /\/Tickets\/#\/?$/.exec(to.fullPath);
  if (homeRoute) {
    next({ path: '/' });
    return;
  }

  next();
});

export default router;

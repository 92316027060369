/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seat-map d-flex flex-no-wrap",class:{ 'is-panning': !!_vm.context.currentPanAction },on:{"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"flex-grow-1 d-flex flex-column",staticStyle:{"min-width":"0"}},[(!_vm.context.allSeats.length)?_c('v-alert',{staticClass:"ma-2",attrs:{"type":"info","outlined":""}},[_vm._v("\n      There don't seem to be any available seats for this event.\n      "),(_vm.$isPublicUser)?_c('span',[_vm._v("Please check back later.")]):_c('span',[_vm._v(" The event is probably misconfigured. ")])]):_c('div',{staticClass:"seat-map-container"},[_c('div',{staticClass:"seat-map-controls",on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('v-sheet',{attrs:{"elevation":"2","rounded":""}},[_c('v-btn',{attrs:{"color":"primary","text":"","icon":"","large":"","disabled":_vm.context.zoom >= _vm.context.zoomMax},on:{"click":function($event){return _vm.context.zoomButton($event, 2)}}},[_c('v-icon',[_vm._v(" fa fa-search-plus ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":"","icon":"","large":"","disabled":_vm.context.zoom <= _vm.context.zoomMin},on:{"click":function($event){return _vm.context.zoomButton($event, 1 / 2)}}},[_c('v-icon',[_vm._v(" fa fa-search-minus ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""},on:{"click":_vm.printSeatMap}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fal fa-download")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Download Seat-map")])])],1)],1),_vm._v(" "),_c('div',{staticClass:"seat-map-scroll-frame",on:{"touchstart":function($event){return _vm.context.onTouchStart($event)},"touchmove":function($event){return _vm.context.onTouchMove($event)},"touchend":function($event){return _vm.context.onTouchEnd($event)},"mousewheel":function($event){return _vm.context.onMouseWheel($event)},"dblclick":function($event){return _vm.context.onDoubleClick($event)}}},[_c('div',{staticClass:"seat-map-drawing-wrapper",class:{
            'show-grid': _vm.context.showGrid,
          },style:({
            // This intermediate wrapping div lets us compensate for the fact that
            // transform:scale() does not shrink the bounding box of an element.
            // What happens without this is that when zoomed < 1, the render and scroll
            // area does not get any shorter in the Y axis. By setting an explicit height
            // with Y overflow hidden, this extra whitespace is removed.
            maxHeight: Math.max(100, _vm.height * _vm.context.zoom) + 'px',
            height: Math.max(100, _vm.height * _vm.context.zoom) + 'px',
            width: Math.max(100, _vm.width * _vm.context.zoom) + 'px',
            overflow: 'hidden',
            '--zoom': _vm.context.zoom,
          })},[_c('vue3-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"preview-modal":true,"filename":"seat-map","pdf-quality":2,"manual-pagination":true,"pdf-format":"a3","pdf-orientation":"landscape","pdf-content-width":"1400px"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"seat-map-drawing",class:{
              'hide-seat-text': _vm.context.hideSeatText,
            },style:({
              width: _vm.width + 'px',
              minWidth: _vm.width + 'px',
              height: _vm.height + 'px',
              transform: _vm.context.drawingTransform,
            }),on:{"mousedown":function($event){return _vm.context.onDrawingMouseDown($event)}}},[_c('seat-map-drawing',{attrs:{"context":_vm.context}}),_vm._v(" "),(_vm.context.selection)?_c('div',{staticClass:"seat-map-selection",style:({
                top: _vm.context.selection.top + 'px',
                left: _vm.context.selection.left + 'px',
                width: Math.abs(_vm.context.selection.clampedWidth) + 'px',
                height: Math.abs(_vm.context.selection.clampedHeight) + 'px',
              })}):_vm._e(),_vm._v(" "),_vm._t("drawing-append")],2)])]),_vm._v(" "),_c('div',{staticClass:"seat-map-drawing",class:{
              'hide-seat-text': _vm.context.hideSeatText,
            },style:({
              width: _vm.width + 'px',
              minWidth: _vm.width + 'px',
              height: _vm.height + 'px',
              transform: _vm.context.drawingTransform,
            }),on:{"mousedown":function($event){return _vm.context.onDrawingMouseDown($event)}}},[_c('seat-map-drawing',{attrs:{"context":_vm.context}}),_vm._v(" "),(_vm.context.selection)?_c('div',{staticClass:"seat-map-selection",style:({
                top: _vm.context.selection.top + 'px',
                left: _vm.context.selection.left + 'px',
                width: Math.abs(_vm.context.selection.clampedWidth) + 'px',
                height: Math.abs(_vm.context.selection.clampedHeight) + 'px',
              })}):_vm._e(),_vm._v(" "),_vm._t("drawing-append")],2)],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
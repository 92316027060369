import Vue from 'vue';
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    noSizeContext: {
      type: Boolean,
      default: false
    }
    // navs: { type: Object, default: null },
  }
  // computed: {
  //   computedNavs() {
  //     const ret = [];
  //     for (const k in this.navs) {
  //       const v = this.navs[k];
  //       if (Array.isArray(v)) {
  //         ret.push(...v.map(nav => ({key: k, value: nav})));
  //       } else {
  //         ret.push({key: k, value: v});
  //       }
  //     }
  //     return ret;
  //   }
  // }
});
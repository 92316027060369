/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.seatMap.annotations),function(annotation){
var _obj;
return _c('div',[_c('div',{staticClass:"drawable annotation",class:( _obj = {
        active: _vm.context.selectedObjects.has(annotation)
      }, _obj['shape-' + _vm.Shape[annotation.shape]] = true, _obj ),style:({
        top: annotation.y + 'px',
        left: annotation.x + 'px',
        width: annotation.width + 'px',
        height: annotation.height + 'px',
      }),on:{"mousedown":function($event){return _vm.context.onObjectMouseDown($event, annotation)},"click":function($event){return _vm.context.onObjectClick($event, annotation)}}},[_c('span',{staticClass:"annotation-label",style:({ 'font-size': annotation.fontScale + 'em' }),domProps:{"textContent":_vm._s(annotation.label)}}),_vm._v(" "),(
          _vm.context.selectedObjects.has(annotation) &&
          _vm.context.selectedObjects.size == 1
        )?_c('div',{staticClass:"resize-handle"}):_vm._e()])])}),_vm._v(" "),_vm._l((_vm.seatMap.sections),function(section,sectionIndex){return _c('div',{key:'section-' + sectionIndex,style:({ '--section-color': section.color })},_vm._l((section.seats),function(seat,seatIndex){return _c('div',{key:'seat-' + sectionIndex + '-' + seatIndex,staticClass:"drawable seat",class:_vm.context.getSeatClasses(seat, section),style:({ top: seat.y + 'px', left: seat.x + 'px' }),attrs:{"title":seat.seatNumber},on:{"mousedown":function($event){return _vm.context.onObjectMouseDown($event, seat)},"click":function($event){return _vm.context.onObjectClick($event, seat)}}},[_c('span',{staticClass:"seat-text",style:({
          fontSize: 1 - 0.14 * (seat.seatNumber.length - 2) + 'em',
        })},[_vm._v("\n        "+_vm._s(seat.seatNumber)+"\n      ")])])}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./purchase-dietary-input-row.vue?vue&type=template&id=4cf85f34&"
import script from "./purchase-dietary-input-row.vue?vue&type=script&lang=ts&"
export * from "./purchase-dietary-input-row.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VExpandTransition,VFadeTransition,VTextarea})

import Vue from 'vue';
export default Vue.extend({
  props: {
    suggestLogin: {
      type: Boolean,
      required: false
    },
    message: {
      type: String,
      required: false
    }
  }
});
/* unplugin-vue-components disabled */import { CLoaderStatus as __unplugin_components_1 } from 'coalesce-vue-vuetify2/lib';
import { CLoaderStatus as __unplugin_components_0 } from 'coalesce-vue-vuetify2/lib';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{attrs:{"loaders":{
    'no-loading-content no-secondary-progress': [_vm.service.getSeating],
  }},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.context)?_c('seat-map-render',{staticClass:"purchase-seat-map can-pan",attrs:{"context":_vm.context}}):_vm._e(),_vm._v(" "),_c('purchase-seat-price-select',{on:{"price-row-click":function (price) { return _vm.addTicket(_vm.priceSelect.seat, price); }},scopedSlots:_vm._u([{key:"before-list",fn:function(){return [_vm._l(((_vm.priceSelect.restrictions || []).filter(
          function (r) { return !!r.agentNote; }
        )),function(restriction,idx){return _c('v-alert',{key:'restriction-' + idx,staticClass:"mb-1",attrs:{"type":"info","dense":""}},[_vm._v("\n        "+_vm._s(restriction.agentNote)+"\n      ")])}),_vm._v(" "),_c(__unplugin_components_1,{attrs:{"loaders":{
          '': [_vm.service.addTicket, _vm.service.removeTicket],
        }}})]},proxy:true},{key:"price-action",fn:function(ref){
        var eventPrice = ref.eventPrice;
return [_c('v-btn',{attrs:{"outlined":"","color":"primary","loading":_vm.service.addTicket.isLoading &&
          _vm.service.addTicket.args.eventPriceId === eventPrice.id,"disabled":_vm.service.addTicket.isLoading &&
          _vm.service.addTicket.args.eventPriceId !== eventPrice.id}},[_vm._v("\n        Select\n      ")])]}}]),model:{value:(_vm.priceSelect),callback:function ($$v) {_vm.priceSelect=$$v},expression:"priceSelect"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
export default Vue.extend({
  props: {
    subject: {
      type: String,
      required: false
    }
  },
  computed: {
    mailtoLink: function mailtoLink() {
      return 'mailto:' + this.APP_SUPPORT_EMAIL + (this.subject ? '?subject=' + encodeURIComponent(this.subject) : '');
    }
  }
});
/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./purchase-dietary-input.vue?vue&type=template&id=d38235ca&"
import script from "./purchase-dietary-input.vue?vue&type=script&lang=ts&"
export * from "./purchase-dietary-input.vue?vue&type=script&lang=ts&"
import style0 from "./purchase-dietary-input.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCheckbox,VExpandTransition})

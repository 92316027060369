/* unplugin-vue-components disabled */import { CDisplay as __unplugin_components_5 } from 'coalesce-vue-vuetify2/lib';
import { CDisplay as __unplugin_components_4 } from 'coalesce-vue-vuetify2/lib';
import { CDisplay as __unplugin_components_3 } from 'coalesce-vue-vuetify2/lib';
import { CDisplay as __unplugin_components_2 } from 'coalesce-vue-vuetify2/lib';
import { CDisplay as __unplugin_components_1 } from 'coalesce-vue-vuetify2/lib';
import { CDisplay as __unplugin_components_0 } from 'coalesce-vue-vuetify2/lib';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"font-size":"20px"}},[_c('strong',[_c(__unplugin_components_0,{attrs:{"model":_vm.eventDate,"for":"eventStartDateTimeLocal","format":"EEEE, MMMM do, yyyy"}})],1),_vm._v("\n    —\n    "),_c('span',{staticStyle:{"white-space":"nowrap"}},[_c(_vm.eventDate.eventBoardingMinutes ? 'span' : 'strong',{tag:"component"},[_c(__unplugin_components_1,{attrs:{"model":_vm.eventDate,"for":"eventStartDateTimeLocal","format":"h:mm a"}})],1),_vm._v("\n      -\n      "),_c(__unplugin_components_2,{attrs:{"model":_vm.eventDate,"for":"eventEndDateTimeLocal","format":"h:mm a"}}),_vm._v(".\n    ")],1)]),_vm._v(" "),_c('div',{staticClass:"bosdy-1 pt-1"},[(
        _vm.eventDate.eventBoardingMinutes && _vm.eventDate.eventBoardingMinutes > 0
      )?[_c(__unplugin_components_3,{attrs:{"model":_vm.eventDate,"for":"eventBoardingLabel"}}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.boardingTimeFormatted))]),_vm._v(" "),(_vm.eventDate.eventLocation)?[_vm._v("\n        at\n        "),_c('strong',[_c(__unplugin_components_4,{attrs:{"model":_vm.eventDate,"for":"eventLocation"}})],1)]:_vm._e(),_vm._v(".\n    ")]:(_vm.eventDate.eventLocation)?[_vm._v("\n      Location:\n      "),_c(__unplugin_components_5,{attrs:{"model":_vm.eventDate,"for":"eventLocation"}}),_vm._v(".\n    ")]:_vm._e()],2),_vm._v(" "),(_vm.eventDate.eventLongDescription)?[_c('v-divider',{staticClass:"my-2"}),_vm._v(" "),_c('span',{staticClass:"text-pre-wrap",domProps:{"textContent":_vm._s(_vm.eventDate.eventLongDescription.trim())}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }